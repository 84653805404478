import React from 'react'
import PropTypes from 'prop-types'
import { SelectLabel, SelectMain, SelectWrapper } from './index.style'
import IconArrowheadDown from '../svgs/IconArrowheadDown'

const Select = ({
  id,
  name,
  label,
  value,
  disabled,
  options,
  onChange,
  ...props
}) => (
  <SelectWrapper>
    <SelectMain
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      disabled={disabled}
      active={value !== ''}
      {...props}
    >
      <option value="" disabled>
        {label}
      </option>

      {React.Children.toArray(
        options.map(option => (
          <option value={option.value}>{option.label}</option>
        ))
      )}
    </SelectMain>

    {value !== '' && <SelectLabel htmlFor={id}>{label}</SelectLabel>}

    <IconArrowheadDown responsive={false} />
  </SelectWrapper>
)

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
}

export default Select
