import React from 'react'

const IconArrowheadDown = ({
  width = 12,
  height = 8,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12 8"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M.293 1.707 1.707.293 6 4.586 10.293.293l1.414 1.414L6 7.414.293 1.707Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default IconArrowheadDown
