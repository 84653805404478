import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import BlogPosts from '../components/BlogPosts'

const Blog = ({ data, location }) => {
  const { seoTitle, seoDescriptions, slug, featuredBlogPost } =
    data.contentfulBlog
  const { nodes: tags } = data.allContentfulTag
  const tagNames = tags.map(tag => ({
    label: tag.name.split(': ')[1],
    value: tag.contentful_id,
  }))

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescriptions?.seoDescriptions}
        pathname={slug}
      />

      <Spacer size={[100, 150]} />

      <BlogPosts
        tags={tagNames}
        blogSlug={slug}
        featuredBlogPost={featuredBlogPost}
        location={location}
      />

      <Spacer size={[50, 120]} />
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query Blog($slug: String!) {
    allContentfulTag(filter: { name: { regex: "/Blog:/" } }) {
      nodes {
        name
        contentful_id
      }
    }
    contentfulBlog(slug: { eq: $slug }) {
      seoTitle
      seoDescriptions {
        seoDescriptions
      }
      seoImage {
        gatsbyImageData
      }
      slug
      featuredBlogPost {
        seoImage {
          gatsbyImageData(quality: 100)
        }
        date(formatString: "MMMM DD, YYYY")
        title
        titleIcon
        slug
      }
    }
  }
`
