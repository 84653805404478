import React from 'react'
import PropTypes from 'prop-types'
import {
  FeaturedBlogCardContent,
  FeaturedBlogCardDot,
  FeaturedBlogCardImage,
  FeaturedBlogCardMain,
} from './index.style'
import DotLabel from '../DotLabel'
import DotHalf from '../svgs/DotHalf'
import { Heading3, TextBodySmall } from '../TextStyles'
import Spacer from '../Spacer'
import Icon from '../Icon'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import AnimateSplitText from '../animation/AnimateSplitText'
import Button from '../Button'
import { GatsbyImage } from 'gatsby-plugin-image'

const FeaturedBlogCard = ({ date, title, titleIcon, to, image }) => (
  <FeaturedBlogCardMain>
    {image && (
      <FeaturedBlogCardImage>
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.description ? image.description : ''}
        />
      </FeaturedBlogCardImage>
    )}

    <FeaturedBlogCardContent>
      <DotLabel>
        <TextBodySmall>{date}</TextBodySmall>
      </DotLabel>

      <Spacer size={[15, 30]} />

      <Heading3 as="h2">
        <AnimateSplitText>
          {title} {titleIcon && <Icon type={titleIcon} />}
        </AnimateSplitText>
      </Heading3>

      <Spacer size={[15, 30]} />

      <AnimateFadeIn>
        <Button type="internalLink" to={to} fill>
          Read more
        </Button>
      </AnimateFadeIn>
    </FeaturedBlogCardContent>

    <FeaturedBlogCardDot>
      <DotHalf />
    </FeaturedBlogCardDot>
  </FeaturedBlogCardMain>
)

FeaturedBlogCard.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  to: PropTypes.string,
  image: PropTypes.object,
}

export default FeaturedBlogCard
