import React, { useCallback, useState, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import queryString from 'query-string'
import dateFormat from 'dateformat'
import PropTypes from 'prop-types'
import {
  BlogPostsCards,
  BlogPostsCardsInner,
  BlogPostsDot,
  BlogPostsDotTwo,
  BlogPostsEmpty,
  BlogPostsLoading,
  BlogPostsLoadingIcon,
  BlogPostsMain,
  BlogPostsPagination,
  Filter,
  Filters,
  FilterSelect,
  FiltersList,
  ShowAllContainer,
} from './index.style'
import Container from '../Container'
import Grid from '../Grid'
import GridItem from '../GridItem'
import Select from '../Select'
import FeaturedBlogCard from '../FeaturedBlogCard'
import Dot from '../svgs/Dot'
import ArticleCard from '../ArticleCard'
import PaginationButton from '../PaginationButton'
import { Heading3, TextBody, TextBodySmall } from '../TextStyles'
import LoadingIcon from '../../../static/images/loader.png'
import Spacer from '../Spacer'
import Button from '../Button'
import Cta from '../Cta'

gsap.registerPlugin(ScrollToPlugin)

const BlogPosts = ({ tags, blogSlug, featuredBlogPost, location }) => {
  const postsPerPage = 10
  const $loading = useRef()
  const $filters = useRef()
  const $cards = useRef()
  const $cardsInner = useRef()
  const { filter: queryFilter, page: queryPage } = queryString.parse(
    location.search
  )
  const [posts, setPosts] = useState([])
  const [numPages, setNumPages] = useState()
  const [currentPage, setCurrentPage] = useState(queryPage || 0)
  const allNewsFilterId = 'all-news'
  const [activeFilter, setActiveFilter] = useState(allNewsFilterId)
  const [activeFilterName, setActiveFilterName] = useState(allNewsFilterId)
  const allTags = [
    {
      value: allNewsFilterId,
      label: 'All News',
    },
    ...tags,
  ]

  const getPosts = useCallback(() => {
    const cardsHeight = $cardsInner.current.offsetHeight
    let filter = ''

    gsap.set($cards.current, { height: cardsHeight })
    setActiveFilterName(activeFilter)
    setPosts([])

    if (activeFilter !== allNewsFilterId) {
      filter = `where: {
        contentfulMetadata: { tags: { id_contains_all: "${activeFilter}" } }
      }`
    }

    fetch(
      'https://graphql.contentful.com/content/v1/spaces/qhtmgs4g40vo?access_token=zEln_h1ML644IxP2uAWGx4h6CZe7csJgKh8wJESMzZs',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `{
          blogPostCollection(
            order: date_DESC
            limit: ${postsPerPage}
            skip: ${currentPage * postsPerPage}
            ${filter}
          ) {
            total
            items {
              title
              date
              titleIcon
              excerpt
              slug
            }
          }
        }`,
        }),
      }
    )
      .then(response => response.json())
      .then(data => {
        setPosts(data.data.blogPostCollection.items)
        setNumPages(
          Math.ceil(data.data.blogPostCollection.total / postsPerPage)
        )

        const newCardsHeight = $cardsInner.current.offsetHeight

        gsap.to($cards.current, {
          height: newCardsHeight,
          ease: 'power2.inOut',
          duration: 0.2,
          onComplete: () => {
            gsap.set($cards.current, {
              clearProps: 'height',
            })
          },
        })

        gsap.to($loading.current, {
          opacity: 0,
          ease: 'power2.inOut',
          duration: 0.2,
        })
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }, [activeFilter, currentPage])

  useEffect(() => {
    if (queryFilter) {
      setActiveFilter(queryFilter)
    }

    if (queryPage) {
      setCurrentPage(Number(queryPage))
    }
  }, [queryFilter, queryPage])

  useEffect(() => {
    gsap.to($loading.current, {
      opacity: 1,
      ease: 'power2.inOut',
      duration: 0.2,
      onComplete: () => getPosts(),
    })
  }, [getPosts])

  const setQueryString = (filter, page) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('filter', filter)
    searchParams.set('page', page)

    window.history.pushState(
      null,
      '',
      `${location.pathname}?${searchParams.toString()}`
    )
  }

  const scrollToFilters = () => {
    gsap.to(window, {
      scrollTo: $filters.current,
      ease: 'power2.inOut',
      duration: 0.4,
    })
  }

  const resetFilters = () => {
    setActiveFilter(allNewsFilterId)
    setQueryString(allNewsFilterId, currentPage)
    scrollToFilters()
  }

  const updateCurrentPage = value => {
    setCurrentPage(value)
    setQueryString(activeFilter, value)
  }

  const setFilter = targetFilter => {
    updateCurrentPage(0)
    setActiveFilter(targetFilter)
    setQueryString(targetFilter, 0)
  }

  const handlePagination = page => {
    scrollToFilters()
    updateCurrentPage(page)
  }

  return (
    <BlogPostsMain>
      <BlogPostsDot>
        <Dot />
      </BlogPostsDot>

      <BlogPostsDotTwo>
        <Dot />
      </BlogPostsDotTwo>

      <Container>
        <Grid>
          {featuredBlogPost && (
            <GridItem desk={14} deskStart={2}>
              <FeaturedBlogCard
                date={featuredBlogPost.date}
                title={featuredBlogPost.title}
                titleIcon={featuredBlogPost.titleIcon}
                to={`${blogSlug}/${featuredBlogPost.slug}/`}
                image={featuredBlogPost.seoImage}
              />
            </GridItem>
          )}

          <GridItem desk={14} deskStart={2}>
            <Filters ref={$filters}>
              <FilterSelect>
                <Select
                  id="blog-filters"
                  name="blog-filters"
                  label="Filter"
                  value={activeFilter}
                  onChange={e => setFilter(e.target.value)}
                  options={allTags}
                />
              </FilterSelect>

              <FiltersList>
                {React.Children.toArray(
                  allTags.map(tag => (
                    <Filter
                      active={activeFilter === tag.value}
                      onClick={() => {
                        setFilter(tag.value)
                      }}
                    >
                      <TextBodySmall>{tag.label}</TextBodySmall>
                    </Filter>
                  ))
                )}
              </FiltersList>
            </Filters>
          </GridItem>

          <GridItem desk={14} deskStart={2}>
            <BlogPostsCards ref={$cards}>
              <BlogPostsCardsInner ref={$cardsInner}>
                {posts.length ? (
                  <Grid columnCount={14}>
                    {React.Children.toArray(
                      posts.map(post => (
                        <GridItem tabletP={7}>
                          <ArticleCard
                            label={dateFormat(post.date, 'mmmm d, yyyy')}
                            title={post.title}
                            titleIcon={post.titleIcon}
                            excerpt={post.excerpt}
                            to={`${blogSlug}/${post.slug}/`}
                          />
                        </GridItem>
                      ))
                    )}
                  </Grid>
                ) : (
                  <BlogPostsEmpty>
                    <Spacer size={[60, 180]} />

                    <Heading3>
                      No articles in “
                      {
                        allTags.filter(tag => tag.value === activeFilterName)[0]
                          .label
                      }
                      ”{' '}
                    </Heading3>

                    <Spacer size={[24, 30]} />

                    <TextBody maxWidth={30.73}>
                      Try visiting us at a later date! Don’t worry, we are
                      regularly adding new articles to stay up to date with the
                      industry.
                    </TextBody>

                    <Spacer size={[32, 50]} />

                    <Button variant="secondary" onClick={resetFilters}>
                      See All News
                    </Button>

                    <Spacer size={[60, 180]} />
                  </BlogPostsEmpty>
                )}
              </BlogPostsCardsInner>

              <BlogPostsLoading ref={$loading}>
                <Spacer size={[60, 180]} />

                <BlogPostsLoadingIcon src={LoadingIcon} alt="" />

                <Spacer size={[60, 180]} />
              </BlogPostsLoading>
            </BlogPostsCards>
          </GridItem>

          {numPages > 1 && (
            <GridItem desk={14} deskStart={2}>
              <BlogPostsPagination
                justify={
                  currentPage >= 1 && currentPage < numPages - 1
                    ? 'split'
                    : currentPage >= 1
                    ? 'left'
                    : 'right'
                }
              >
                {currentPage >= 1 && (
                  <PaginationButton
                    onClick={() => handlePagination(currentPage - 1)}
                  >
                    previous
                  </PaginationButton>
                )}

                {currentPage < numPages - 1 && (
                  <PaginationButton
                    onClick={() => handlePagination(currentPage + 1)}
                    back={false}
                  >
                    next
                  </PaginationButton>
                )}
              </BlogPostsPagination>
            </GridItem>
          )}
          <GridItem desk={14} deskStart={2}>
            <ShowAllContainer>
              <Cta type="internalLink" to={`/blogs/all/`}>
                Show all
              </Cta>
            </ShowAllContainer>
          </GridItem>
        </Grid>
      </Container>
    </BlogPostsMain>
  )
}

BlogPosts.propTypes = {
  tags: PropTypes.array,
  blogSlug: PropTypes.string,
  featuredBlogPost: PropTypes.object,
  location: PropTypes.object,
}

export default BlogPosts
