import styled, { keyframes } from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { ArticleCardMain } from '../ArticleCard/index.style'
import { ButtonIcon } from '../Button/index.style'

export const BlogPostsMain = styled.div`
  position: relative;
  overflow: hidden;

  ${ArticleCardMain} {
    height: 100%;
  }
`

export const BlogPostsDot = styled.div`
  position: absolute;
  ${clamp('top', 136, 180)}
  left: 0;
  transform: translateX(-50%);
`

export const BlogPostsDotTwo = styled.div`
  position: absolute;
  ${clamp('top', 192, 236)}
  right: 0;
  transform: translateX(50%);
`

export const BlogPostsCards = styled.div`
  position: relative;
  overflow: hidden;
`

export const BlogPostsCardsInner = styled.div``

export const BlogPostsLoading = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  text-align: center;
  background-color: ${colors.light};
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const BlogPostsLoadingIcon = styled.img`
  width: 13rem;
  margin-left: auto;
  margin-right: auto;
  animation: ${rotate} 1.1s linear infinite;
`

export const BlogPostsEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const BlogPostsPagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ justify }) =>
    justify === 'split'
      ? 'space-between'
      : justify === 'left'
      ? 'flex-start'
      : 'flex-end'};
  padding-top: 1.8rem;

  > * + * {
    margin-left: 2.4rem;
  }

  ${ButtonIcon} {
    width: auto;
  }
`

export const Filters = styled.div`
  max-width: 117rem;
  margin-left: auto;
  margin-right: auto;
  ${clamp('padding-top', 10, 48)}
  ${clamp('padding-bottom', 1, 28)}
`

export const FiltersList = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  ${mq.tabletL} {
    display: flex;
  }
`

export const Filter = styled.button`
  position: relative;
  display: inline-flex;
  appearance: none;
  border: none;
  background: none;
  color: currentColor;
  opacity: ${props => (props.active ? 1 : 0.5)};
  transition: opacity 0.2s ${easings.inOut.default};

  :hover,
  :focus {
    opacity: 1;
  }

  ::before {
    content: '';
    position: absolute;
    bottom: -0.1rem;
    right: 0;
    left: 0;
    transform-origin: 0% 50%;
    transform: scaleX(${props => (props.active ? 1 : 0)});
    display: block;
    height: 0.1rem;
    background-color: ${colors.dark};
    transition: transform 0.2s ${easings.inOut.default};
  }
`

export const FilterSelect = styled.div`
  ${mq.tabletL} {
    display: none;
  }
`

export const ShowAllContainer = styled.div`
  display: flex;
  justify-content: center;
`
