import { transparentize } from 'polished'
import styled, { css } from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'

const fontSizeMax = 28
const inputTextStyles = css`
  ${clamp('font-size', 20, fontSizeMax)}
  line-height: 1.2;
  letter-spacing: -0.01em;
`

const horizontalPadding = `${50 / fontSizeMax}em`

export const SelectWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    right: 4rem;
    transform: translate(0, -50%);
  }
`

export const SelectMain = styled.select`
  appearance: none;
  color: currentColor;
  display: block;
  width: 100%;
  padding: ${props =>
    props.active
      ? `${44 / fontSizeMax}em ${horizontalPadding} ${22 / fontSizeMax}em`
      : `${33 / fontSizeMax}em ${horizontalPadding}`};
  ${inputTextStyles}
  border: 0.1rem solid ${transparentize(0.8, colors.dark)};
  ${clamp('border-radius', 10, 20)}
  background-color: transparent;
`

export const SelectLabel = styled.label`
  position: absolute;
  top: 50%;
  left: ${horizontalPadding};
  transform-origin: 0% 0%;
  transform: translateY(-50%);
  ${inputTextStyles}
  transition: transform 0.15s ease, opacity 0.15s ease;
  will-change: transform, opacity;

  ${SelectWrapper}:focus-within &,
  ${SelectMain}:not(:placeholder-shown) + & {
    transform: translateY(-86%) scale(0.6);
    opacity: 0.6;
  }
`
