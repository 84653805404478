import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const FeaturedBlogCardMain = styled.div`
  position: relative;
  background-color: ${colors.grey};
  border: 0.1rem solid ${colors.grey};
  border-color: ${colors.grey};
  ${clamp('border-radius', 10, 20)}
  transition-property: background-color, border-color;
  transition-duration: 0.3s;
  transition-timing-function: ${easings.inOut.default};
`

export const FeaturedBlogCardDot = styled.div`
  position: absolute;
  top: 38%;
  left: 0;
  transform: translateX(-50%) rotate(180deg);
  opacity: 0.9;
`

export const FeaturedBlogCardImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  ${clamp('border-radius', 10, 20)}
  overflow: hidden;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    pointer-events: none;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }
`

export const FeaturedBlogCardContent = styled.div`
  position: relative;
  ${clamp('padding-bottom', 50, 85)}
  ${clamp('padding-right', 20, 85)}
  ${clamp('padding-left ', 20, 85)}
  color: ${colors.light};

  ${mq.deskMMax} {
    ${clamp('padding-top', 178, 200, breakpoints.mobile, breakpoints.deskM)}
  }

  ${mq.deskM} {
    ${clamp('padding-top', 200, 400, breakpoints.deskM, breakpoints.deskL)}
  }
`
